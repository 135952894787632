import {
  cardTitle,
  container,
  description,
  subtitle,
  title,
} from "assets/jss/material-kit-pro-react.jsx"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx"

const blogPostsStyle = (theme) => ({
  ...modalStyle(theme),
  blogPostsButton: {
    border: "2px solid #377358",
    color: "#377358 !important",
    backgroundColor: "#fff !important",
    borderRadius: "5px",
  },
  blogPostsImage: {
    maxHeight: "500px",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  card: {
    margin: "0px",
    "& .MuiGrid-container": {
      width: "100%",
    },
  },
  cardCategory: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    marginBottom: "0",
    marginTop: "10px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      lineHeight: "0",
    },
    color: "#e4240ecc",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  cardIcon: {
    fontSize: "1rem",
    marginRight: "3px",
    "& path": {
      color: "#e4240ecc",
    },
  },
  cardTitle,
  cardTitleSmall: {
    ...cardTitle,
    textAlign: "center !important",
  },
  chiliBorder: {
    borderWidth: "50px",
    borderStyle: "solid",
    borderBottom: "0px",
    width: "50px",
  },
  container,
  description,
  externalLink: {
    ...description,
    color: "#777",
    "&:hover": {
      color: "#6d91e4",
    },
  },
  lastestNewsItem: {
    textAlign: "left",
  },
  modalBlogPostsDescription: {
    ...description,
    padding: "0px 20px 10px 20px",
    textAlign: "center",
    color: "#777",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  modalBlogPostsImage: {
    maxWidth: "400px",
    margin: "20px auto 50px auto",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  },
  modalBlogPostsFooter: {
    padding: "15px",
    textAlign: "right",
    margin: "0",
    justifyContent: "center",
    borderTop: "1px solid #ccc",
    paddingTop: "15px",
  },
  modalBlogPostsList: {
    textAlign: "left",
    "& li": {
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
    },
  },
  modalBlogPostsTitle: {
    ...title,
    lineHeight: "1.5",
    textAlign: "center",
  },
  modalCardCategory: {
    textTransform: "uppercase",
    color: "#377358",
    display: "flex",
    alignItems: "center",
    margin: "0",
    "& path": {
      color: "#377358 !important",
    },
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
  },
  imageSubtitle: {
    fontSize: "10px",
    textAlign: "center",
    width: "75%",
    margin: "-30px auto 0 auto",
  },
  subtitle: {
    ...description,
    textAlign: "center",
  },
  tabSpacer: {
    height: "75px",
  },
  title: {
    ...title,
    textAlign: "center",
  },
  titleEmpty: {
    ...title,
    textAlign: "center",
    margin: "0px !important",
  },
  videoWrapper: {
    maxWidth: "500px",
    margin: "20px auto",
  },
})

export default blogPostsStyle
